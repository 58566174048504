<template>
  <v-card>
    <v-img
      class="elevation-3 tc-image"
      :src="image"
    />
    <v-card-title
      class="tool-card-title"
      v-text="title"
    />
    <v-card-subtitle
      v-if="subtitle"
      v-text="subtitle"
    />
    <div class="align-center d-flex pa-5">
      <slot />
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'ToolCard',
  props: {
    title: { type: String, default: '', required: true},
    subtitle: { type: [String,Boolean], default: false, required: false},
    image: { type: String, default: '', required: true},
  },
  data(){
    return {
      tokenId:null
    }
  }
}
</script>
<style scoped lang="scss">
.tc-image{
  height: 250px;
}
  .tool-card-title{
    word-break: unset;
    overflow-wrap: break-word;
  }
</style>