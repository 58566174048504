<template>
  <tool-card v-bind="$attrs">
    <slot name="textField">
      <v-text-field
        v-model="tokenId"
        type="number"
        class="pr-3"
        max="6000"
        hint="Token Number"
        prepend-icon="mdi-download-circle"
      />
    </slot>
    <slot name="button">
      <v-btn
        :disabled="!tokenId"
        target="_blank"
        :href="`${actionUrl}${tokenId}`"
      >
        Download
      </v-btn>
    </slot>
  </tool-card>
</template>

<script>
import ToolCard from "@/components/cards/ToolCard";
export default {
  name: "TokenToolCard",
  components: {ToolCard},
  props: {
    actionUrl: {
      type: String,
      default: '',
      required:true,
    },
  },
  data() {
    return {
      tokenId: false,
    }
  },
}
</script>

<style scoped>

</style>