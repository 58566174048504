<template>
  <v-card
    class="pa-md-10 d-lg-flex d-sm-block"
    width="100%"
  >
    <v-col>
      <v-card-title v-text="'Tools'" />
      <v-card-subtitle v-text="'In this section youll be able to find some tools for your artless NFTs, if you need anything not listed in here, feel free to contact us through discord.'" />
      <v-row>
        <v-col
          v-for="card of cards"
          :key="card.title"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          cols="12"
        >
          <token-tool-card
            v-if="card.token"
            v-bind="card"
          />
          <tool-card
            v-else
            v-bind="card"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import TokenToolCard from "@/components/cards/tokenToolCard";
import ToolCard from "@/components/cards/ToolCard";

export default {
  name: "ArtlessTools",
  components: {ToolCard, TokenToolCard},
  data() {
    return {
      tokenId: '',
      cards:[
        {
          token: true,
          title:'Geometria HD Video Download',
          subtitle: 'This tool was developed by Sunflower Industries and will help you generate an hd video of your ' +
              'token, just set your token number and click on download.',
          image:require('@/assets/images/geometria.png'),
          actionUrl:'https://art-sandbox.sunflower.industries/tokenVideoDownload/0x566309d3523a216287bfb39c6b6bbb3a504ef5f0/',
        },
        {
          token: true,
          title:'Curvy Dancers HD Video Download',
          subtitle: 'This tool was developed by Sunflower Industries and will help you generate an hd video of your ' +
              'token, just set your token number and click on download.',
          image:require('@/assets/images/curvydancers.png'),
          actionUrl:'https://art-sandbox.sunflower.industries/tokenVideoDownload/0xded87c5e52c2ddc1934c10e751a1756c4f99ca98/',
        }
      ]
    }
  },
}
</script>

